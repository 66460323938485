import { useTypedSelector_v2 } from '@hooks_v2/index';
import NextImage, { StaticImageData } from 'next/image';
import { __StaticImg } from 'public/assets/images.asset';
import React from 'react';
import { _globalStore } from 'store.global';
let mediaBaseUrl = _globalStore.blobUrl; // for server side

export const generateImageUrl = (
  src: null | string,
  mediaBaseUrl: string,
): string => {
  if (src === null) return __StaticImg.product.src;

  if (typeof src === 'string') {
    const with_or_without_HTTP = src.includes('http');

    if (with_or_without_HTTP) return src;
    if (!with_or_without_HTTP) return `${mediaBaseUrl}${src}`;
  }

  return __StaticImg.noImageFound.src;
};

const NxtImage: React.FC<_Imageprops> = ({
  role = 'none',
  src,
  alt,
  cKey,
  width,
  title,
  height,
  className,
  isStatic = false,
  useNextImage = false,
  layout = 'responsive',
  extraUrl,
  decoding,
  extraUrlPath,
  style,
  sizes = '',
  loading,
  dataPinNopin = false
}) => {
  const clientSideMediaBaseUrl = useTypedSelector_v2(
    (state) => state.store.mediaBaseUrl,
  );
  mediaBaseUrl = mediaBaseUrl || clientSideMediaBaseUrl;

  if (isStatic) {
    if (typeof src !== 'string') {
      return (
        <img
          width={width}
          src={src?.src}
          height={height}
          alt={alt || ''}
          itemProp='image'
          title={title || ''}
          className={className}
          role={role}
          loading={loading}
          style={style}
          decoding={decoding}
          sizes={sizes}
          data-pin-nopin={`${dataPinNopin}`}
        />
      );
    }

    if (typeof src === 'string') {
      return (
        <img
          width={width}
          src={src}
          height={height}
          sizes={sizes}
          alt={alt || ''}
          itemProp='image'
          loading={loading}
          style={style}
          title={title || ''}
          className={className}
          data-pin-nopin={`${dataPinNopin}`}
        />
      );
    }
  }

  const imageUrl = generateImageUrl(src as string, mediaBaseUrl);

  if (imageUrl === 'https://storagemedia.corporategear.com/') {

    return <></>;
  }

  if (useNextImage) {
    return (
      <div className={className}>
        <NextImage
          title={title}
          src={imageUrl}
          layout={layout}
          alt={alt || ''}
          key={cKey || 0}
          loading={'eager'}
          width={width || 1}
          height={height || 1}
          sizes={sizes}
          style={style}
        />
      </div>
    );
  }

  if (extraUrlPath) {
    return (
      <img
        src={(extraUrlPath + imageUrl) as string}
        alt={alt || ''}
        title={title || ''}
        className={className}
        sizes={sizes}
        style={style}
        loading={loading}
        data-pin-nopin={`${dataPinNopin}`}
      />
    );
  }

  return (
    <img
      itemProp='image'
      src={imageUrl as string}
      alt={alt || ''}
      sizes={sizes}
      title={title || ''}
      style={style}
      loading={loading}
      className={className}
      data-pin-nopin={`${dataPinNopin}`}
    />
  );
};

export default NxtImage;

export interface _Imageprops {
  isStatic?: boolean;
  sizes?: string;
  src: null | string | StaticImageData;
  alt: string | null;
  className: string;
  width?: number | string;
  height?: number | string;
  cKey?: number | string;
  useNextImage?: boolean;
  layout?: 'intrinsic' | 'fill' | 'responsive';
  title?: string;
  extraUrl?: boolean;
  extraUrlPath?: string;
  role?: string;
  loading?: 'lazy' | 'eager' | undefined;
  decoding?: 'async';
  style?: React.CSSProperties | undefined;
  dataPinNopin?: boolean
}

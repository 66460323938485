// import { _GetPageType } from '@type/slug.type';
import { __domain } from '@configs/page.config';
import { _GetPageType } from '@definations/slug.type';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import OgTags from './OgTags';
interface _Props {
  storeName: string | null;
  mediaBaseUrl: string;
  pageMetaData: _GetPageType | null;
  pageUrl: string;
  imageUrl: string;
}
const Metatags: React.FC<_Props> = (props) => {
  const router = useRouter();
  let pathInfo = router.asPath.split("?");
  let tmpPath = pathInfo[0].split("/");
  return (
    <>
      <Head>
      {router.asPath.includes('/stories/') ? 
              <link rel='canonical' href={`https://${__domain.localDomain}/stories/${tmpPath[tmpPath.length-1]}`} /> 
              : 
              <link rel='canonical' href={`https://${__domain.localDomain}/${tmpPath[tmpPath.length-1]}`} />}
        <OgTags
          mediaBaseUrl={props.mediaBaseUrl}
	  imageUrl={props.imageUrl}          
          storeName={props.storeName}
          pageMetaData={props.pageMetaData}
          pageUrl={props.pageUrl}
        />
      </Head>
    </>
  );
};

export default Metatags;

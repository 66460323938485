import SuccessErrorModal from '@appComponents/modals/successErrorModal';
import CloseStore from '@appComponents/reUsable/CloseStore';
import { _Store, storeBuilderTypeId } from '@configs/page.config';
import { paths } from '@constants/paths.constant';
import {
  _AnnouncementRow,
  _MenuItems,
  _StoreMenu,
} from '@definations/header.type';
import { _GetPageType, _StoreCache } from '@definations/slug.type';
import { _FetchStoreConfigurations } from '@definations/store.type';
import { _templateIds } from '@helpers/app.extras';
import { addCustomEvents } from '@helpers/common.helper';
import BreadCrumb from '@templates/breadCrumb';
import Footer from '@templates/Footer';
import * as _AppController from 'controllers_v2/_AppController.async';
import { useActions_v2, useTypedSelector_v2 } from 'hooks_v2';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import Header from 'Templates/Header';
import storeDetails from 'staticData/storeDetails.json';
import { fetchZipcodefromGeolocation } from '@helpers/getLocation';

interface _props {
  children: React.ReactNode;
  configs: {
    footer: _FetchStoreConfigurations | null;
    header: _FetchStoreConfigurations | null;
  };
  store: {
    id: number;
    sbStore: any;
    typeId: number;
    code: string;
    logoUrl: string;
    menuItems: _StoreMenu[] | null;
  };
  templateIDs: _templateIds;
  pageMetaData: _GetPageType;
}

const Layout: React.FC<_props> = ({
  children,
  store,
  configs,
  templateIDs,
  pageMetaData,
}) => {
  const [header, setHeader] = useState<{
    storeCode: string;
    logoUrl: string;
    menuItems: _MenuItems | null;
  }>({
    storeCode: store.code,
    logoUrl: store.logoUrl,
    menuItems: {
      items: store.menuItems,
      items_content: null,
    },
  });
  const [headerTemplateId, setHeaderTeamplateId] = useState<string>(
    templateIDs.headerTemplateId,
  );
  const [breadCrumbTemplateId, setBreadCrumbTemplateId] = useState<string>(
    templateIDs.breadCrumbsTemplateId,
  );
  const [isCountryDisabled, setIsCountryDisabled] = useState<boolean>(false);

  const [headerBgColor, setHeaderBgColor] = useState<string>('');
  const [headerTextColor, setHeaderTextColor] = useState<string>('');
  const [announcementRow, setAnnouncementRow] = useState<_AnnouncementRow[]>(
    [],
  );
  const [footerData, SetFooterData] =
    useState<_FetchStoreConfigurations | null>(null);
  const router = useRouter();

  const [showFooter, setshowFooter] = useState<boolean>(true);



 
  const callMenuItemsAPI = async () => {
    // await _AppController.fetchMenuItems(store.id).then((response) => {
    //   setHeader((prev) => ({
    //     ...prev,
    //     menuItems: {
    //       items: response,
    //       items_content: null,
    //     },
    //   }));
    // });

    await _AppController
      .fetchMenuContents(store.menuItems, store.id, store.code)
      .then((response) => {
        if (!store.menuItems || store.menuItems.length === 0) return;
        if (!response || response.length === 0) return;

        setHeader((prev) => ({
          ...prev,
          menuItems: {
            items: store.menuItems,
            items_content: response,
          },
        }));
      });
  };

  useEffect(() => {
    callMenuItemsAPI();

    if (configs?.header?.config_value) {
      const headerInfo = JSON.parse(configs.header.config_value);
      setHeaderBgColor(headerInfo?.header_bg_color);
      setHeaderTextColor(headerInfo?.header_text_color);

      headerInfo?.announcementRow &&
        setAnnouncementRow(headerInfo?.announcementRow);
    }

    if (localStorage) {
      addCustomEvents('localStorage');
    }
  }, []);

  useEffect(() => {
    if (router.asPath == paths.CHECKOUT) {
      setshowFooter(false);
    } else {
      setshowFooter(true);
    }
    if (configs.footer != null) {
      SetFooterData(configs.footer);
    }
  }, [router.asPath]);

  const headerMenu = useMemo(() => header.menuItems, [header.menuItems])

  const fetchLocationAndBlockedZone = async () => {
    const geoLocation = await fetchZipcodefromGeolocation();
    try {
      if (geoLocation?.country_code) {
        // const growingZone = await fetchGrowingZone(geoLocation?.postal, storeDetails?.storeId);
        if (storeDetails.blockCountryList?.length > 0) {
          const isCountry = storeDetails.blockCountryList.find(
            (obj:any) => obj?.twoLetterCode === geoLocation?.country_code,
          );

          if (isCountry) setIsCountryDisabled(true);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    fetchLocationAndBlockedZone();
  }, []);

  const isbreadcrumbShow = pageMetaData?.isbreadcrumbShow;
  const showBreadcrumb =
    router.pathname !== paths.CHECKOUT &&
    router.asPath != paths.BRAND &&
    router.pathname !== paths.PRODUCT_COMPARE &&
    router.pathname !== paths.CATALOGS &&
    router.pathname !== paths.CART;

  return (
    <>
       {isCountryDisabled ? (
        <>
        <div className='flex justify-center items-center h-screen w-screen'>
          <p className='font-bold'>Site not available for this Country.</p>
        </div>
        </>
      ) : (
        <>
      <Header
        storeCode={header.storeCode}
        logoUrl={{
          desktop: header.logoUrl,
        }}
        menuItems={headerMenu}
        headerTemplateId={headerTemplateId}
        headerBgColor={headerBgColor}
        headerTextColor={headerTextColor}
        announcementRow={announcementRow}
      />

      {(pageMetaData &&
      pageMetaData.type &&
      pageMetaData.type.toLowerCase() === 'topic'
        ? isbreadcrumbShow && isbreadcrumbShow == 'Y'
        : true) &&
        showBreadcrumb && (
          <>
            <BreadCrumb breadCrumbid={breadCrumbTemplateId} />
          </>
        )}
      <SuccessErrorModal />
      <div style={{ flexGrow: 1 }}>{children}</div>
      {showFooter && (
        <Footer data={configs.footer ? configs.footer : footerData} />
      )}
      </>
      )
}
    </>
  );
};

export default Layout;

import Script from 'next/script';

function DataDogRum() {
  return (
    <>
    
      <Script id='data-dog' strategy='afterInteractive'>
        {` (function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    d=o.createElement(u);d.async=1;d.src=n
    n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
  })(window,document,'script','https://www.datadoghq-browser-agent.com/us5/v5/datadog-rum.js','DD_RUM')
  window.DD_RUM.onReady(function() {
    window.DD_RUM.init({
      clientToken: 'pub6d0dca0ca291f65bed4501719dab53c3',
      applicationId: '4751d28d-e121-4b46-a518-7c2c5c92e891',
      site: 'us5.datadoghq.com',
      service: 'pkhealthgear',
      env: 'production',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0', 
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  })`}
      </Script>
    </>
  );
}

export default DataDogRum;

import { __pagesText } from '@constants/pages.text';
import { _MenuCategory } from '@definations/header.type';
import { FetchMenuCategories } from '@services/header.service';
import { useActions_v2, useTypedSelector_v2 } from 'hooks_v2';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import SubCategoryItem from './Header_SubCategoryItem';

interface _props {
  itemLabel: string;
  itemUrl: string;
  type: 'BRAND' | 'CATEGORY';
  view: 'DESKTOP' | 'MOBILE';
  itemId?: number;
  setSubTab?: (args: string) => void;
  Subtab?: string;
}

const SubMenuItem: React.FC<_props> = ({ type, itemLabel, itemUrl, view }) => {
  const { toggleSideMenu } = useActions_v2();

  if (type === 'BRAND') {
    if (view === 'MOBILE') {
      return (
        <li
          className='py-[12px] border-b border-b-gray-border'
          onClick={() => toggleSideMenu('CLOSE')}
        >
          <Link
            href={`/${itemUrl}`}
            className='text-anchor hover:text-anchor-hover '
          >
            <a
              className='relative inline-block pl-[50px] leading-[18px] text-[#000000]'
              title={itemLabel}
            >
              {itemLabel}
            </a>
          </Link>
        </li>
      );
    }
    if (view === 'DESKTOP') {
      return (
        <li className='flex pt-[12px]'>
          <span className='material-icons-outlined text-[18px] leading-none font-[100] mr-[5px]'>
            {__pagesText.Headers.rightArrowIcon}
          </span>
          <Link href={`/${itemUrl}`}>
            <a
              className='inline-block text-[14px] text-quaternary font-[400] tracking-[1px] leading-[18px]'
              title={itemLabel}
            >
              {itemLabel}
            </a>
          </Link>
        </li>
      );
    }
  }

  if (type === 'CATEGORY') {
    if (view === 'MOBILE') {
      return (
        <li
          className='py-[12px] border-b border-b-gray-border'
          onClick={() => toggleSideMenu('CLOSE')}
        >
          {/* <span className='material-icons-outlined text-lg'>chevron_right</span> */}
          <Link
            href={`/${itemUrl}`}
            className='text-anchor hover:text-anchor-hover'
          >
            <a
              className='relative inline-block pl-[50px] leading-[18px] text-[#000000]'
              title={itemLabel}
            >
              {itemLabel}
            </a>
          </Link>
        </li>
      );
    }

    if (view === 'DESKTOP') {
      return (
        <li className='flex pt-[12px] '>
          <span className='material-icons-outlined text-[18px] leading-none font-[100] mr-[10px]'>
            {__pagesText.Headers.rightArrowIcon}
          </span>
          <Link href={`/${itemUrl}`}>
            <a
              className='inline-block text-[14px] text-quaternary font-[400] tracking-[1px] leading-[18px]'
              title={itemLabel}
            >
              {itemLabel}
            </a>
          </Link>
        </li>
      );
    }
  }

  return <></>;
};

export default SubMenuItem;

import { __LocalStorage } from '@constants/global.constant';
import { paths } from '@constants/paths.constant';
import { _AnnouncementRow } from '@definations/header.type';
import { useActions_v2, useTypedSelector_v2 } from '@hooks_v2/index';
import { useRouter } from 'next/router';
import React from 'react';
interface _props {
  headerContent: _AnnouncementRow;
}
const UpperHeader: React.FC<_props> = ({ headerContent }) => {
  const router = useRouter();
  const {
    setShowLoader,
    updateEmployeeV2,
    product_employeeLogin,
    logoutClearCart,
    logInUser,
  } = useActions_v2();
  const employeeDetails = useTypedSelector_v2((state) => state.employee);

  const leftcontent = headerContent.leftSideText
    ? headerContent.leftSideText
    : '';

  const rightContent = headerContent.rightSideText
    ? headerContent.rightSideText
    : '';
  const storeCode = useTypedSelector_v2((state) => state.store.code);
  const logoutEmployeeHandler = () => {
    // Don't logout LoggedIn user
    setShowLoader(true);
    updateEmployeeV2('CLEAN_UP');
    product_employeeLogin('MinQtyToOne_CleanUp');
    // logoutClearCart();
    // logInUser('CLEAN_UP');

    // setCookie(__Cookie.userId, '', 'EPOCH');
    // deleteCookie(__Cookie.tempCustomerId);
    localStorage.removeItem(__LocalStorage.guestEmailID);
    localStorage.removeItem(__LocalStorage.empData);
    localStorage.removeItem(__LocalStorage.empGuest);
    router.push(paths.HOME);
  };
  if (router.pathname === paths.CHECKOUT) return <></>;
  if (headerContent.isVisible) {
    return (
      <>
        <div className=''>
          <div className={`bg-${headerContent?.backgroundColor || 'primary'} hidden md:block`}  style={{ height: storeCode === 'PKHG' ? "30px" : "", color: headerContent?.textColor || '#fff' }}>
            <div className='container pl-[15px] pr-[15px] mx-auto'>
              <div className='flex flex-wrap justify-between items-center'>
                {employeeDetails.empId || rightContent ? <div className='' dangerouslySetInnerHTML={{ __html: leftcontent }} /> : <div className='w-full text-center' dangerouslySetInnerHTML={{ __html: leftcontent }} /> }
                
                {(employeeDetails.empId || rightContent) && (
                <div className='flex items-center gap-3'>
                {employeeDetails.empId ? (
                      <>
                  <div className='flex items-center gap-3'>
                    
                       {storeCode === 'CG' && <>
                        <span
                          onClick={() => router.push(paths.DISCOUNTED)}
                          className='text-center text-capitalize cursor-pointer text-[#ffffff]'
                        >
                          Discontinued
                        </span>
                        <span className='p-l-5 p-r-5 text-[#ffffff]'>|</span></>}
                        <span className='text-center text-capitalize cursor-pointer text-[#ffffff]'>
                          Employee Logged In
                          <button
                            className='ml-1'
                            style={{ color: '#7BC24E' }}
                            onClick={logoutEmployeeHandler}
                          >
                            (LogOut)
                          </button>
                        </span>
                     {rightContent &&   <span className='p-l-5 p-r-5 text-[#ffffff]'>|</span>}
                     
                  </div>
                  </>
                    ) : null}


                  <div dangerouslySetInnerHTML={{ __html: rightContent }} />
                </div>)}


              </div>
                
            </div>
          </div>
        </div>
      </>
    );
  } else if (!!employeeDetails.empId) {
    return (
      <>
        <div className=''>
          <div className={`bg-${headerContent?.backgroundColor || 'primary'} hidden md:block`}  style={{ height: storeCode === 'PKHG' ? "30px" : "", color: headerContent?.textColor || '#fff' }} >
            <div className='container pl-[15px] pr-[15px] mx-auto'>
              <div className='flex flex-wrap justify-between items-center'>
                {/* <div dangerouslySetInnerHTML={{ __html: leftcontent }} /> */}
                <div></div>
                <div className='flex items-center gap-3'>
                  <div className='flex items-center gap-3'>
                    {employeeDetails.empId ? (
                      <>
                        {storeCode === 'CG' && <>
                          <span
                            onClick={() => router.push(paths.DISCOUNTED)}
                            className='text-center text-capitalize cursor-pointer text-[#ffffff]'
                          >
                            Discontinued
                          </span>
                          <span className='p-l-5 p-r-5 text-[#ffffff]'>|</span></>}
                        <span className='text-center text-capitalize cursor-pointer text-[#ffffff]'>
                          Employee Logged In
                          <button
                            className='ml-1'
                            style={{ color: '#7BC24E' }}
                            onClick={logoutEmployeeHandler}
                          >
                            (LogOut)
                          </button>
                        </span>
                        {rightContent && <span className='p-l-5 p-r-5 text-[#ffffff]'>|</span>}
                      </>
                    ) : null}
                  </div>
                  {/* <div dangerouslySetInnerHTML={{ __html: rightContent }} /> */}
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return <></>
};

export default UpperHeader;

import {
  _HeaderPropsWithTemplateid,
  _HeaderTemplates,
} from '@definations/header.type';
import { NextPage } from 'next';
import LowerHeader from './Components/LowerHeader';
import UpperHeader from './Components/UpperHeader';
import Header_Type2 from './header_Type2';

const Header: NextPage<_HeaderPropsWithTemplateid> = ({
  storeCode,
  logoUrl,
  headerBgColor,
  headerTextColor,
  menuItems,
  announcementRow,
}) => {
  const HeaderTemplate = Header_Type2;

  return (
    <div className={`sticky top-0 md:top-7 z-40`} id={`MainHeader`}>
      {announcementRow && announcementRow[0] && (
        <UpperHeader headerContent={announcementRow[0]} />
      )}
      <HeaderTemplate
        storeCode={storeCode}
        logoUrl={logoUrl}
        menuItems={menuItems}
        headerBgColor={headerBgColor}
        headerTextColor={headerTextColor}
      />
      {announcementRow && announcementRow[1]?.isVisible && (
        <LowerHeader headerContent={announcementRow[1]} />
      )}
    </div>
  );
};

export default Header;

export const __pagesText = {
  dst: 'digital logo',
  jpg: 'jpeg logo',
  msrp: 'MSRP',
  adaCorporateGear: {
    header: `Our Commitment to Accessibility`,

    para1: `Corporate Gear is committed to making our website content
    accessible and user friendly to everyone. If you are
    having difficulty viewing or navigating the content on
    this website, or notice any content, feature, or
    functionality that you believe is not fully accessible to
    people with disabilities, please call our Customer Service
    team or email us and provide a description of the specific
    feature you feel is not fully accessible or a suggestion
    for improvement.`,

    para2: `We take your feedback seriously and will consider it as we
    evaluate ways to accommodate all of our customers and our
    overall accessibility policies. Additionally, while we do
    not control such vendors, we strongly encourage vendors of
    third-party digital content to provide content that is
    accessible and user friendly.`,

    contact_info: `Contact Info:`,

    phone_contact: `Call us at (888) 293-5648`,

    email_contact: `Email us at support@corporategear.com`,
  },
  requestConsultation: {
    heading: 'Request Consultation & Proof',
    contactInformation: 'Contact Information',
    captchaNotValid: 'Captcha is not valid !',
    allFields: 'All fields marked * are required.',
    thankYou: 'Thank you! Your request has been received.',
    customerServiceContact:
      'One of our customer service representatives will contact you shortly.',
    shop: 'SHOP',
    oneMillion: ' OVER 1 MILLION PRODUCTS DECORATED',
    timmings:
      'We are here to help Monday through Friday from 9 am to 5 pm EST.',
    contactUs: 'CONTACT US',
    number: '888-293-5648',
  },
  productListing: {
    Banner: {
      yourLogo: 'Your Logo',
      loginforExclusivePrice:
        ' LOGIN OR CREATE AN ACCOUNT TO SEE EXCLUSIVE PRICING',
      freeShipping: 'FREE SHIPPING ON',
      orderOver: 'ORDERS OVER $2,500',
      firstLogoFree: '1ST LOGO FREE',
      uptoTenThousandStiches: 'UP TO 10,000 STITCHES',
      freeProof: 'FREE PROOF',
      onAllOrders: 'ON ALL ORDERS',
      shippingIcon: 'local_shipping',
      location: 'TO ONE LOCATION',
      shiping: 'FREE SHIPPING',
      drawIcon: 'draw',
      verifyIcon: 'verified',
    },
    loadMoreButton: 'LOAD MORE',
    clearAllButton: 'Clear All',
    noProductsFound: 'no products found',
    gridViewIcon: 'grid_view',
    listViewIcon: 'view_agenda',
    totalCountresult: 'Results',
    checkIcon: 'check',
    Filters: 'Filters',
    closeIcon: 'close',
    rightArrowIcon: 'chevron_right',
    products: 'Products',
    PRICE: 'PRICE ',
    MSRP: 'MSRP ',
  },
  home: {},
  ContactUs: {
    contactUsDescription:
      'Our approach as an extension of your brand is to help maintain and build your brand equity with the right partners in the corporate marketplace.',
    contactUs: 'Contact Us',
    chat: 'Chat',
    call: 'Call',
  },
  review: {
    heading: 'Review',
    noReviewAvailable: 'NO REVIEWS ARE AVAILABLE FOR THIS PRODUCT.',
    wantToHearFromYou: 'WE WANT TO HEAR FROM YOU',
    tellUsWhatyouThink:
      'Tell us what you think about this item. It helps us get better at what we do, and ultimately provide you with better products',
    writeAReview: 'Write A Review',
    foundHelpfull: 'people found this helpful',
    wasThisHelpfull: 'Was This Helpful? ',
    blank: '',
    hateIt: 'I HATE IT',
    loveIt: 'I LOVE IT',
    ok: "IT'S OK",
    DontLike: "I DON'T LIKE IT",
    likeIt: 'I LIKE IT',
    someThingWrong: 'Something went wrong. Try again, Later!!!',
    titleError: 'Error',
    writeReview: 'Write Review',
    postPublicly: 'POST PUBLICLY AS:',
    clear: 'CLEAR',
    reviewSorting: {
      mostRecent: 'Most Recent',
      positiveFirst: 'Positive First',
      negativeFirst: 'Negative First',
      negative: 'negative',
      positive: 'positive',
      latest: 'latest',
    },
    form: {
      description: 'Description For your review',
      headline: 'Headline For your review',
      fileupload: 'Select files to upload',
      dragAndDrop: 'Drag your files here or click in this area.',
      multiple: 'or click to select files',
      submit: 'Submit',
    },
  },
  productInfo: {
    pricePerItem: 'Price Per Item:',
    productCode: 'Product Code',
    someThingWentWrong: 'Something Went Wrong!!!',
    pleaseUploadLogoLater: 'Please Upload Logo or Add Logo Later for',
    pleaseUploadLogo: 'Please Upload Logo or Choose Add Logo Later',
    logo: 'Logo',
    addSomeQty:`Please add some quantity `,
    minimumOrderForThisColor: 'The minimum order for this color is',
    pleaseIncreaseYourQuantityAndTryAgain:
      'pieces. Please increase your quantity and try again.',
    tenPlus: '10+',
    productRecentlyViewed: {
      recentlyReviews: 'Recent reviews',
      outOf5Starts: 'out of 5 stars',
    },
    notesPk: {
      minimumPiecePerColor: `We request that all orders include a minimum of 4 pieces per color of a style, which can be split across men's and women's companion items. We may reject or require modification to any orders not meeting these requirements without prior approval.`,
      backordered: `If you are ordering product that is backordered, your entire order will not ship until all items are available. Click the number in the Availability column above to see future inventory dates. Please reference the ship date shown in your cart.`,
      pleaseNote: 'PLEASE NOTE:',
      minimumPiecePerColorWithoutLogin:
        'PLEASE SIGN INTO YOUR ACCOUNT TO VIEW LIVE INVENTORY AND VOLUME DISCOUNTS',
    },
    checkInventoryPricing: 'Check Inventory And Your Pricing',
    addTocart: 'ADD TO CART',
    buyNow: 'BUY NOW',
    contactUs: 'Contact us for Available Inventory',
    capContactUs: 'CONTACT US FOR AVAILABLE INVENTORY',
    callUs: 'CALL US FOR PRICE',
    Discontinued: 'Discontinued',
    somLogoOption: {
      addLogoLater: 'Add Logo Later',
      logoToBeSubmitted: 'Logo to be submitted after order is placed',
      logoToBe: 'Logo to be submitted',
      submitted: 'after order is placed',
      remove: 'Remove',
      selectYourLogo: 'Select your logo:',
      upload: 'Upload',
      edit: 'Edit',
      selectALocationToPrintLogo: 'Select a location to print your logo:',
      uploadFromLibrary: 'Upload From Library',
    },
    productReviewRating: {
      customerPhotos: 'Customer Photos',
      customerReview: 'Customer Reviews',
      shareYourThought: 'Share your thoughts',
      ifYouHaveUsedThisProductShareThought:
        'If you’ve used this product, share your thoughts with othercustomers',
      overAllRating: 'OVERALL RATING',
      basedOn: 'Based on',
      reviews: 'reviews',
    },
    quantity: 'Quantity:',
    quantityDisc: 'QUANTITY DISCOUNT',
    price: 'Price:',
    visibility: 'visibility',
    confirm: 'Confirm',
    checkAvailableInventory: 'Check Available Inventory',
    sizeChart: 'Size Chart',
    additionalDetails: 'Additional details',
    requestAFreeConsulation:
      'Or request a free consultation with one of our experts',
    sku: 'SKU',
    ProductCode: 'Product Code:',
    msrp: 'MSRP',
    product_code: 'Product Code:',
    description: 'Description',
    requestConsultaionProof: 'Request Consultation & Proof',
    availableSizes: 'Available Size(s):',
    companionProduct: 'Companion Product',
    companion: 'COMPANION',
    availableColors: {
      availableColors: 'Available Color',
      selectColor: 'Select Color:',
      showless: 'Show Less',
      seeAll: 'See All',
      colors: 'Colors:',
      onlycolor: 'Color',
    },
    forgotModal: {
      forgotPassword: 'Forgot Password',
      back: 'Back',
      notFound: `We're sorry. We weren't able to identify you given the information provided.`,
      sucessmesage:
        'Instructions for creating a new password have been emailed to you.',
      sentSucess1: 'An email has been sent to ',
      sentSucess2:
        'with a link to reset your password. It may take a few minutes to arrive. If you dont see it in your inbox, please check your spam folder.',
    },
    loginModal: {
      requestLogin: 'Exisiting Customer Requesting Online Account',
      signIn: 'Sign In',
      signUp: 'Sign Up',
      shopNow: 'SHOP NOW',
      keepMeLoggedIn: 'Keep me logged in',
      infoButton: {
        yourPasswordMustHave: 'Your password must have:',
        orMoreCharaters: '6 Or more character',
        atLeastOneNumber: 'At least one number',
        upperAndLowerCaseLetters: 'Upper and lowercase letters',
      },
      forgotPassword: 'Forgot Password?',
      createNewAccount: 'CREATE NEW CUSTOMER ACCOUNT',
      newCustomer: 'New Customer Requesting a Driving Impression Account',
      samllogin: 'LOGIN VIA SAML',
      emailPlaceHolder: 'Enter the email address',
      passwordPlaceHolder: 'Password',
      clickMessage: ` By Clicking "SHOP NOW", you agree to our`,
      termsOfUse: 'Terms of use',
      and: 'and',
      privacyPolicy: 'Privacy policy',
      pkIntroText:
        'Health by ParsonsKellogg provides exclusive access to Patagonia apparel and accessories to the healthcare industry. Please log in or create an account to shop now.',
    },
    startOrderModal: {
      color: 'Color: ',
      notes: 'Notes:',
      itemTotal: 'Item Total:',
      orderInMultipleOf: 'ORDER IN MULTIPLES OF ',
      productInformation: 'Product information',
      customizeLogoOption: {
        customizeYoutOrder: 'Customize Your Order:',
        customizeLogoLater:
          'Customize Logo Later with Dedicated Account Specialist',
        customizeLogoNow: 'Customize Logo Now',
      },
      calculativeFigure: {
        quantitySelected: 'Quantity Selected:',
        pricePerItem: 'Price Per Item:',
        firstLogo: 'First Logo:',
        additionalLogos: 'Additional Logo(s):',
        subTotal: 'Subtotal:',
        additionalSewOutCharges: 'SewOut Charges:',
      },
      askToLogin: {
        loginOrCreateAnAccount: 'LOGIN OR CREATE AN ACCOUNT',
        toSeeDiscountPricing: 'to see exclusive pricing on this product.',
        loginCreateAccount: 'Login / Create an account',
      },
      sizePriceQty: {
        size: 'Size',
        price: 'Price',
        qty: 'Qty',
        selectOrInput: {
          thanksForSigningUp: 'Thanks for signing up!',
          outOfStockGetInventoryAlert: 'Out of Stock. Get Inventory Alert.',
          email: 'email',
          notify: 'NOTIFY ME',
          save: 'SAVE',
          clear: 'CLEAR',
          cancel: 'CANCEL ',
        },
      },
    },
    discountPricing: {
      discountPricing: 'Discount Pricing:',
      startshowMsg:
        'We reserve the right to reject orders that do not meet the ',
      endShowMsg:
        ' piece minimum per style and color, exceptions may apply for men’s and women’s companion styles per color.',
      exclusivePricingAvailable: 'EXCLUSIVE PRICING AVAILABLE',
      exclusivePricing: 'EXCLUSIVE PRICING',
      selectSizeAndQuantity: 'Select Size & Quantity:',
      minimumOrder: 'MINIMUM ORDER: ',
      minimumOrderQuantity: 'MINIMUM ORDER QUANTITY:',
      price: 'Price:',
      MSRP: 'MSRP: ',
      showMsgStartingText:
        'We reserve the right to reject orders that do not meet the',
      showMsgMiddleText: ' piece minimum per style',
      showMsgLastText:
        ' and color, exceptions may apply for men’s and women’s companion styles per color.',
      firstMsg: 'We reserve the right to reject orders that do not meet the',
      secondMsg: `piece minimum per style 
      and color, exceptions may apply for',
       men’s and women’s companion styles per color.`,

      perItem: 'per item',
    },
    availableInventoryModal: {
      availableInventory: 'Available Inventory',
      close: 'Close',
    },
  },
  accountPage: {
    firstName: 'First Name',
    lastName: 'Last Name',
    emailAddress: 'Email Address',
    companyName: 'Company Name',
    currentPassword: 'Current Password',
    youtPasswordMustHave: 'Your password must have:',
    eightMore: '6 Or more character',
    sixMore: '6 Or more character',
    upperOrLowerCase: 'Upper and lowercase letters',
    atLeastOneNumber: 'At least one number',
    passwordUpdate: 'Update Password',
    passwordChange: 'CHANGE',
    profileEdit: 'Edit Profile',
    saveBtn: 'Save',
    cancelBtn: 'Cancel',
    gender: 'Gender',
    genderOptions: ['Gender', 'Male', 'Female', 'Others'],
    birthDay: 'Birthday',
    genderOption: [
      {
        value: '',
        name: 'Select Gender',
      },
      {
        value: 'Male',
        name: 'Male',
      },
      {
        value: 'Female',
        name: 'Female',
      },
      {
        value: 'Others',
        name: 'Others',
      },
    ],
    birthDate: 'Birthdate',
    password: 'Password',
    visibility: 'visibility',
    confirmPassword: 'Confirm Password',
    info: 'info',
    phone: 'Phone',
    primarySport: 'Primary Sport ',
    organizationText: 'Your School / University / Organization Details',
    organizationNameText: 'School / University / Organization Name',
    organizationType: ' School / Organization Type',
    personalInformation: 'Personal Information',
    yourPosition: 'Your Position',
    addressOne: ' Address 1',
    zipCode: 'Zip Code',
    city: 'City',
    state: 'State',
    country: 'Country',
    selectGender: 'Select Gender',
    purchaseTimeText: 'Time Of Year You Purchase',
    selectPurchaseTimeText: 'Select Time Of Year You Purchase',
    teamGender: 'Team Gender',
    teamGenderOption: [
      { value: '', name: 'Select Gender' },
      { value: 'Boys', name: 'Boys' },
      { value: 'Girls', name: 'Girls' },
      { value: 'Both', name: 'Both' },
    ],
    selectColorText: ' Select Color Of School / University / Organization',
    primaryColor: 'Primary Color',
    mascot: 'Mascot',
    numberOfPlayersText: ' Number Of Players OR Members',
    countryOption: ['United States', 'Canada'],
    selectCountry: 'Select Country',
    selectState: 'Select State',
    stateOptions: ['Alabama', 'Alaska', 'California'],
    yourAddress: 'Your Address',
    signUp: 'Sign Up',
    selectMonth: 'Select Month',
    mascotOptions: [
      { value: 0, name: 'Select Mascot' },
      { value: 1, name: 'Eagles' },
      { value: 2, name: 'Tigers' },
      { value: 3, name: 'Panthers' },
      { value: 4, name: 'Bulldogs' },
      { value: 5, name: 'Warriors' },
      { value: 6, name: 'Wildcats' },
      { value: 7, name: 'Lions' },
      { value: 8, name: 'Cougars' },
      { value: 9, name: 'Knights' },
      { value: 10, name: 'Indians' },
      { value: 11, name: 'Falcons' },
      { value: 12, name: 'Trojans' },
      { value: 13, name: 'Vikings' },
      { value: 14, name: 'Spartans' },
      { value: 15, name: 'Rams' },
      { value: 16, name: 'Patriots' },
      { value: 17, name: 'Cardinals' },
      { value: 18, name: 'Hornets' },
      { value: 19, name: 'Raiders' },
      { value: 20, name: 'Pirates' },
      { value: 21, name: 'Crusaders' },
      { value: 22, name: 'Hawks' },
      { value: 23, name: 'Bears' },
      { value: 24, name: 'Bobcats' },
      { value: 25, name: 'Jaguars' },
      { value: 26, name: 'Pioneers' },
      { value: 27, name: 'Saints' },
    ],
    organizationTypes: [
      {
        value: 1,
        name: 'High School',
      },
      {
        value: 2,
        name: 'College',
      },
      {
        value: 3,
        name: 'Club',
      },
      {
        value: 4,
        name: 'Corporate',
      },
    ],
    primarySports: [
      {
        value: 0,
        name: 'Select Sport',
      },
      {
        value: 1,
        name: 'Baseball',
      },
      {
        value: 2,
        name: 'Basketball',
      },
      {
        value: 3,
        name: 'Cheer',
      },
      {
        value: 4,
        name: 'Field Hockey',
      },
      {
        value: 5,
        name: 'Fishing',
      },
      {
        value: 6,
        name: 'Football',
      },
      {
        value: 7,
        name: 'Golf',
      },
      {
        value: 8,
        name: 'Golf Collection',
      },
      {
        value: 9,
        name: 'Ice Hockey',
      },
      {
        value: 10,
        name: 'Lacrosse',
      },
      {
        value: 11,
        name: 'Mountain Sports',
      },
      {
        value: 12,
        name: 'Sailing',
      },
      {
        value: 13,
        name: 'Skiing',
      },
      {
        value: 14,
        name: 'Snowboard',
      },
      {
        value: 15,
        name: 'Soccer',
      },
      {
        value: 16,
        name: 'Softball',
      },
      {
        value: 17,
        name: 'Swim',
      },
      {
        value: 18,
        name: 'Tennis',
      },
      {
        value: 19,
        name: 'Track and Field',
      },
      {
        value: 20,
        name: 'Volleyball',
      },
      {
        value: 21,
        name: 'Wrestling',
      },
      {
        value: 22,
        name: 'Other',
      },
    ],
    monthOption: [
      'Month',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    day: 'Day',
    dayOption: [
      'Day',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ],
    yearOption: [
      'Year',
      '2023',
      '2022',
      '2021',
      '2020',
      '2019',
      '2018',
      '2017',
      '2016',
      '2015',
      '2014',
      '2013',
      '2012',
      '2011',
      '2010',
      '2009',
      '2008',
      '2007',
      '2006',
      '2005',
      '2004',
      '2003',
      '2002',
      '2001',
      '2000',
      '1999',
      '1998',
      '1997',
      '1996',
      '1995',
      '1994',
      '1993',
      '1992',
      '1991',
      '1990',
      '1989',
      '1988',
      '1987',
      '1986',
      '1985',
      '1984',
      '1983',
      '1982',
      '1981',
      '1980',
      '1979',
      '1978',
      '1977',
      '1976',
      '1975',
      '1974',
      '1973',
      '1972',
      '1971',
      '1970',
      '1969',
      '1968',
      '1967',
      '1966',
      '1965',
      '1964',
      '1963',
      '1962',
      '1961',
      '1960',
      '1959',
      '1958',
      '1957',
      '1956',
      '1955',
      '1954',
      '1953',
      '1952',
      '1951',
      '1950',
      '1949',
      '1948',
      '1947',
      '1946',
      '1945',
      '1944',
      '1943',
      '1942',
      '1941',
      '1940',
      '1939',
      '1938',
      '1937',
      '1936',
      '1935',
      '1934',
      '1933',
      '1932',
      '1931',
      '1930',
      '1929',
      '1928',
      '1927',
      '1926',
      '1925',
      '1924',
      '1923',
    ],
  },
  Headers: {
    notificationText:
      'Free Logo &amp; Proof on All Orders + Free Shipping on Orders Over $2,500',
    order: 'Order',
    accountSetting: 'Account Settings',
    logoLocation: 'Logo Location',
    manageLoco: 'Manage Logo',
    help: 'Help',
    signOut: 'Sign Out',
    logOut: 'Logout',
    login: 'Login',
    loginIcon: 'account_circle',
    mycart: 'my cart',
    shoppingCartIcon: 'shopping_cart',
    qty: 'QTY',
    subtotal: 'SUBTOTAL',
    totalItemInCartMessage: 'ITEMS IN CART',
    total: 'Total',
    checkoutNow: ' CHECKOUT NOW',
    mobileViewAll: 'View all',
    searchIcon: 'search',
    searchPlaceholder: 'Search',
    rightArrowIcon: 'chevron_right',
    myAccountTittle: 'My Account',
    companyPhoneQuestion: 'Questions? Call us at',
    companyEmailQuestion: 'or Email',
    companyEmailQuestionOr: 'Email',
    samllogin: 'LOGIN VIA SAML',
  },
  Wishlist: {
    wishlist: 'WISHLIST',
    removeButton: 'Remove',
    viewButton: 'View',
    emptyWishlistEmptyTitle: 'Wishlist is Empty.',
    emptyWishlistEmptyDescription: `There's nothing in your wishlist.`,
    notToWorry: 'Not to worry: we have lots of other great finds.',
    startShopping: 'START SHOPPING',
  },
  PageNotFound: {
    pageNotFound: 'PAGE NOT FOUND',
    backToHome: 'BACK TO HOME PAGE',
  },
  dicheckoutNote:
    'If a valid resale certificate is not provided prior to shipment, the applicable sales tax will be calculated and charged.',
  CheckoutPageCardNote1: {
    note: 'Please note that the tax amount on this order is subject to change based on the final invoice, and in accordance with local and state laws',
  },
  CheckoutPageCardNote2: {
    note: 'Your credit card will be authorized at checkout and you will see a pending charge on your bank statement. A sales representative will review the order details once received. Your card will then be charged in 5-7 days after submitted unless confirmed as finalized or otherwise cancelled prior to then.',
  },
  ThankYouPage: {
    ThankYouFooter: {
      heading: 'OVER 1 MILLION PRODUCTS DECORATED',
      description:
        'We are here to help Monday through Friday from 9am to 5pm EST',
    },
    Total: 'Product Total:',
    ThankYouLabel: 'Thank You,',
    ForYourOrder: 'FOR YOUR ORDER',
    OrderNumber: 'YOUR ORDER NUMBER IS: ',
    FirstEmailMessage: 'You will receive a confirmation shortly at ',
    LastEmailMessage: ', You can access your account at your account ',
    PrintReciept: 'Print Receipt',
    Questions: 'Questions?',
    CallUs: 'Call Us: ',
    Email: 'Email',
    AccordianHeader: 'Detailed Order Receipt: ',
    TotalSummary: {
      SubTotal: 'Sub Total:',
      LinePersonalization: 'Line Personalization:',
      LogoCost: 'Additional Logo Costs:',
      Shipping: 'Shipping:',
      Tax: 'Tax:',
      SewOut: 'Sew Out:',
      couponDiscountAmount: 'Coupon Discount:',
      GrandTotal: 'Grand Total:',
      Sku: 'SKU:',
      Color: 'Color:',
      ItemTotal: 'Item Total: ',
      ItemDetails: 'Item Details',
      smallRunningFee: 'Small Run Fee',
      orderLogoSetupFee: 'Logo Setup Fee',
      customizationCost: 'Customization Cost: ',
      ShippingHandling: 'Shipping & Handling:	',
      Yousave: 'You save:	',
      GrandTotals: 'Grand Total:',
      InternalCredit: 'Internal Credit:',
      Total: 'Total:',
    },
    LogoToBe: 'Logo to be',
    Submitted: 'submitted',
    Logo: 'logo',
    Location: 'Location:',
    LogoPrice: 'Logo Price',
    FirstLogoFree: 'First Logo Free',
    CreatePassword: 'Create Password',
    CreateAccount: 'Create Account',
    Billing: {
      BillTo: 'Bill to:',
      ShipTo: 'Ship to:',
    },
  },
  BreadCrumbs: {
    back: 'Back',
  },
  YouMayAlsoLike: {
    YouMayAlsoLike: 'YOU MAY ALSO LIKE',
  },
  ManageLogo: {
    Waiting: 'Waiting for Approval',
    Approved: 'Approved',
    ApprovedDate: 'Approval Date:',
    Logo: 'Logo',
    ApprovedLogo: 'Approved Logo',
    LogoName: 'Logo Name',
    LogoNumber: 'Logo Number',
    LogoSize: 'Logo Size',
    ProductType: 'Product Type',
    LogoPosition: 'Logo Position',
    Status: 'Status',
    Heading: 'MANAGE LOGO',
    LogoUploadedOn: 'Logo uploaded on: ',
    setAsDefault: 'Set as Default',
    removeAsDefault: 'Remove as Default',
    delete: 'Delete',
  },
  CheckoutPage: {
    Change: 'Change',
    Edit: 'Edit',
    GoToPayment: 'GO TO PAYMENT DETAILS',
    GotoReview: 'GO TO REVIEW ORDER',
    secure128: 'This is a secure 128-bit SSL encrypted method',
    OrderReview: 'Order Review',
    BillingInformation: 'Billing Information',
    BillingAddress: 'Billing Address',
    paymentInfo: 'Provide payment details below',
    ShippingMethod: 'Shipping Method',
    ShippingMethods: 'Shipping Methods',
    PaymentMethod: 'Payment Method',
    payment: 'Payment',
    ShippingAddress: 'Shipping Address',
    ChooseShippingMethod: 'Choose Shipping Method',
    placeOrder: 'PLACE ORDER NOW',
    AddressInstruction: 'All Fields marked * are required fields.',
    cardInstruction:
      'The last three digits \n displayed on the \n back of your card \n or first four \n digits on the front \n of your AMEX.',
    orderSummary: {
      tax: 'Tax:',
      OrderSummary: 'Order Summary',
      cartSummary: 'Cart Summary',
      Merchandise: 'Merchandise:',
      Discount: 'Discount:',
      Subtotal: 'Subtotal:',
      FirstLogo: 'First Logo:',
      SecondLogo: 'Second Logo:',
      ThirdLogo:'Third Logo:',
      FourthLogo:'Fourth Logo:',
      ShippingHandling: 'Shipping & Handling:',
      SmallRunFee: 'Small Run Fee:',
      InternalCredit: 'Internal Credit:',
      Tax: 'Tax:',
      EstimatedTotal: 'Estimated Total:',
      LinePersonalization: 'Line Personalization:',
      CartSummarryInstruction: [
        `We request that all orders include a minimum of 4 pieces per color of a style, which can be split across men's and women's companion items. We may reject or require modification to any orders not meeting these requirements without prior approval.`,
        `Please note that the tax amount on this order is subject to change based on the final invoice, and in accordance with local and state laws`,
        `Your credit card will be authorized at checkout and you will see a pending charge on your bank statement. A sales representative will review the order details once received. Your card will then be charged in 5-7 days after submitted unless confirmed as finalized or otherwise cancelled prior to then.`,
      ],
    },
  },
  OrderPage: {
    yourOrder: 'Your Order',
    usersOrder: 'Users Order',
    emptyOrder: 'Orders is Empty',
    emptyOrderMessage1: 'There is nothing in your Order',
    emptyOrderMessage2: ' Not to worry: we have lots of other great finds.',
    startShopping: 'START SHOPPING',
    orderNumber: 'ORDER NUMBER',
    userName: ' User Name',
    dateOfOrder: ' DATE OF ORDER',
    totalPrice: '   TOTAL PRICE',
    orderStatus: 'ORDER STATUS',
    viewOrderDetails: 'View Order Details',
    sku: 'SKU ',
    size: 'SIZE',
    color: 'COLOR',
    price: 'PRICE',
    qty: 'QTY',
    unitTotal: 'UNIT TOTAL',
    estimatedPrice: 'ESTIMATED PRICE',
    writeaReview: ' Write A Review',
    copyRight:"PKhealthgear"
  },
  CustomizeLater:
    'A Gear Expert will contact you to discuss the customization of this product.',
  CheckLogoApproved: {
    submitFeedback: 'Submit your feedback',
    approveLogo: 'Approve your logo',
    or: 'OR',
    comment: 'Comment:',
    EmbroideryColor: 'Embroidery color:',
    LogoSize: 'Logo size:',
    LogoUploadedOn: 'Logo uploaded on:',
    jpegLogo: 'JPEG LOGO',
    dstLogo: 'DST LOGO',
  },
  logoPrint: {
    Note: '*Please Note: The above logo may not reflect the actual selected colors.',
  },
  cart: {
    successMessage: 'Added to cart Successfully',
    removeItem: 'Remove Item',
    remove: 'Remove',
    personalize: 'Personalize ',
    yourItem: 'Your Item',
    emptyCart: 'Your Cart is Empty.',
    emptyCartMessage1: `There's nothing in your cart.`,
    emptyCartMessage2: '  Not to worry: we have lots of other great finds.',
    startShopping: 'START SHOPPING',
    checkOutNow: 'CHECKOUT NOW',
    continueShopping: 'CONTINUE SHOPPING',
    shoppingCart: 'Shopping Cart',
    chestLineLimit:
      'Limit 26 characters (including spaces) for Chest personalization.',
    sleeveLineLimit:
      'Limit 20 characters (including spaces) for Sleeve personalization.',
    simpliSafeQtyLimit:
      'customers may redeem 1 shirt only. if you need to make changes to your order please contact customer service at 866-602-8398 x 122 or using the live chat located at the bottom of the right page.',
  },
  closedStore: {
    closedStoreMessage: ` This store is no longer active. Please check with your coach or
    director to see if/when a new store will be opened. Thank You!`,
  },

  contactRequest: {},
  PKGH_special_request_form: {
    formTitle: 'PATAGONIA CUSTOM REQUEST FORM',
    formDescription: ` Are you looking to order a Patagonia style not featured in our catalog?
    We’d be glad to help! Please keep in mind, any requested styles that are
    not featured in our catalog are subject to a 10-piece minimum per style
    and color, which may be split between sizes and men’s and ladies’
    styles. Availability advertised on Patagonia.com is a separate
    allocation htmlFor retail and not reflective of what we may access. To
    submit a non-catalog request, please complete the below form with as
    much detail as possible. You will be contacted by a Sales Rep, who will
    work with you to fulfill the order.`,
  },
};
